<template>
  <div
    class="images-with-testimonial"
    :class="themeColorClass"
  >
    <UiHeader
      :header="header"
      :header-position="headerPosition"
      :header-layout="headerLayout"
      :header-size="headerSize"
      :theme-color="themeColor"
    />

    <div class="images-with-testimonial__content">
      <div class="images-with-testimonial__images">
        <div
          v-if="subheader"
          class="images-with-testimonial__images-header"
          v-text="subheader"
        />

        <div
          v-if="imageList.length"
          class="images-with-testimonial__images-container"
        >
          <template v-for="image in imageList">
            <div
              v-if="image"
              :key="image.id"
            >
              <component
                :is="image.link ? 't3-link' : 'div'"
                :to="image.link || null"
              >
                <UiImg
                  :image="image"
                  :height="128"
                />
              </component>
            </div>
          </template>
        </div>
      </div>

      <span />

      <div class="images-with-testimonial__testimonial">
        <UiImg
          :image="image"
          :height="192"
        />

        <T3HtmlParser
          v-if="bodytext"
          :content="bodytext"
          class="images-with-testimonial__testimonial-text"
        />

        <div
          v-if="testimonialImageMeta"
          class="images-with-testimonial__testimonial-meta"
        >
          <span
            v-if="testimonialImageMeta.alternative"
            class="images-with-testimonial__testimonial-meta-name"
            v-text="testimonialImageMeta.alternative"
          />
          <span
            v-if="testimonialImageMeta.description"
            class="images-with-testimonial__testimonial-meta-label"
            v-text="testimonialImageMeta.description"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { UiHeader, UiImg } from '~ui/components'
import useTheming from '~ui/composables/useTheming'
import type { UiCeCaseStudyBannerProps } from '~ui/types'

const props = defineProps<UiCeCaseStudyBannerProps>()

const themeColorClass = useTheming(props.themeColor, 'variant')

const testimonialImageMeta = computed(() => {
  const image = Array.isArray(props.image)
    ? props.image?.[0] ?? {}
    : props.image

  return image.properties || false
})

const imageList = computed(() => {
  return props.tagList
    .map((tag) => {
      if (!tag.image?.[0]) return false
      return {
        ...tag.image[0],
        id: tag.image[0].properties.fileReferenceUid,
        link: tag.link
      }
    })
    .filter(Boolean)
})
</script>

<style lang="scss">
.images-with-testimonial {
  & .ui-header {
    margin-bottom: rem(32px);

    @include media-query(sm) {
      margin-bottom: rem(48px);
    }

    & > * {
      margin-bottom: 0;
    }
  }

  &__content {
    display: grid;

    @include media-query(max sm) {
      grid-template-rows: auto rem(48px) auto;
    }

    @include media-query(sm) {
      grid-template-columns: repeat(7, 1fr) rem(48px) repeat(5, 1fr);

      & > * {
        grid-row: 1;
      }
    }

    & > span {
      @include media-query(max sm) {
        height: 50%;
        width: 100%;
        border-bottom: 1px solid var(--color__border-color);
      }

      @include media-query(sm) {
        height: 100%;
        width: 50%;
        border-right: 1px solid var(--color__border-color);
      }
    }
  }

  &__testimonial {
    @include media-query(sm) {
      grid-column: 1 / 8;
    }

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, min-content);
    gap: rem(16px);

    & .ui-img {
      grid-row: 1;
      grid-column: 1;
      border-radius: 50%;
      height: rem(48px);
    }

    &-text {
      grid-column: 2;
      grid-row: 1;
      color: var(--color__font-color--text);

      & > * {
        font-size: rem(16px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &-meta {
      grid-column: 2;
      grid-row: 2;
      display: flex;
      flex-direction: column;
      padding-left: rem(16px);
      border-left: 1px solid var(--variant__gradient-300);
      font-weight: 500;

      &-name {
        font-size: rem(18px);
        color: var(--color__font-color--subheading);
      }

      &-label {
        font-size: rem(16px);
        color: var(--variant__theme-color);
      }
    }
  }

  &__images {
    @include media-query(sm) {
      grid-column: 9 / -1;
    }

    &-header {
      font-size: rem(22px);
      font-weight: 600;
      color: var(--color__font-color--subheading);

      & + * {
        margin-top: rem(16px);
      }
    }

    &-container {
      display: flex;
      flex-wrap: wrap;
      gap: rem(8px) rem(32px);

      & > div {
        padding: rem(16px) rem(10px);

        & .ui-img {
          height: rem(32px);
        }
      }
    }
  }
}
</style>
